import type { BonusNotification } from '../types'

export function useBonusNotification(notification: Ref<BonusNotification>) {
  const { t } = useI18n()

  const bonusTitle = computed(
    () =>
      ({
        cashback: t('notifications.subject.cashback'),
        reload: t('notifications.subject.reload'),
        rakeBack: t('notifications.subject.rakeback'),
        ratRace: t('notifications.subject.ratRace'),
        quest: t('notifications.subject.quest'),
        bonusForDeposit: t('notifications.subject.bonusForDeposit'),
      })[notification.value.data.bonusProgramType] ??
      t('notifications.subject.bonus'),
  )
  const bonusType = computed(() => notification.value.data.bonusProgramType)
  const bonusIcon = computed(
    () =>
      ({
        ratRace: 'cup-solid' as const,
        cashback: 'cashback' as const,
        rakeBack: 'cashback' as const,
        reload: 'gift-solid' as const,
        quest: 'award' as const,
        bonusForDeposit: 'gift-solid' as const,
      })[bonusType.value] ?? ('gift-solid' as const),
  )
  const bonusText = computed(
    () =>
      ({
        ratRace:
          notification.value.type === 'bonusCreated'
            ? t('notifications.bonuses.ratRace')
            : t('notifications.bonuses.ratRaceExpired'),
        cashback:
          notification.value.type === 'bonusCreated'
            ? t('notifications.bonuses.cashback')
            : t('notifications.bonuses.cashbackExpired'),
        rakeBack:
          notification.value.type === 'bonusCreated'
            ? t('notifications.bonuses.rakeback')
            : t('notifications.bonuses.rakebackExpired'),
        reload:
          notification.value.type === 'bonusCreated'
            ? t('notifications.bonuses.reload')
            : t('notifications.bonuses.reloadExpired'),
        quest:
          notification.value.type === 'bonusCreated'
            ? t('notifications.bonuses.quest')
            : t('notifications.bonuses.questExpired'),
        bonusForDeposit: '',
      })[bonusType.value] ?? '',
  )
  const bonusLink = computed(() => {
    switch (notification.value.type) {
      case 'bonusCreated':
        return '/personal/transactions?tab=deposit'
      case 'bonusExpired':
        return '/bonuses'
      default:
        return ''
    }
  })

  return {
    bonusTitle,
    bonusType,
    bonusIcon,
    bonusText,
    bonusLink,
  }
}
