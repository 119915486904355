<template>
  <NotificationBase :notification :icon :link="bonusLink" :compact>
    <template #subject>
      {{ bonusTitle }}
    </template>
    <template v-if="bonusType === 'bonusForDeposit'">
      <i18n-t
        v-if="notification.type === 'bonusCreated'"
        keypath="notifications.body.bonusForDepositCreated"
        tag="p"
      >
        <template #bonus>
          {{ bonusText }}
        </template>
        <template #amount>
          <b>{{ notificationAmount }}</b>
        </template>
        <template #currencyCode>
          <b>{{ notification.data.currencyCode }}</b>
        </template>
      </i18n-t>
      <i18n-t
        v-else
        keypath="notifications.body.bonusForDepositExpired"
        tag="p"
      >
        <template #bonus>
          {{ bonusText }}
        </template>
        <template #amount>
          <b>{{ notificationAmount }}</b>
        </template>
        <template #currencyCode>
          <b>{{ notification.data.currencyCode }}</b>
        </template>
      </i18n-t>
    </template>
    <template v-else>
      <i18n-t
        v-if="notification.type === 'bonusCreated'"
        keypath="notifications.body.bonusCreated"
        tag="p"
        class="text"
      >
        <template #bonus>
          {{ bonusText }}
        </template>
        <template #amount>
          <b>{{ notificationAmount }}</b>
        </template>
        <template #currencyCode>
          <b>{{ notification.data.currencyCode }}</b>
        </template>
      </i18n-t>
      <i18n-t
        v-else
        keypath="notifications.body.bonusExpired"
        tag="p"
        class="text"
      >
        <template #bonus>
          {{ bonusText }}
        </template>
        <template #amount>
          <b>{{ notificationAmount }}</b>
        </template>
        <template #currencyCode>
          <b>{{ notification.data.currencyCode }}</b>
        </template>
      </i18n-t>
    </template>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { BonusNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: BonusNotification
  compact?: boolean
}>()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)
const { bonusType, bonusText, bonusIcon, bonusLink, bonusTitle } =
  useBonusNotification(toRef(() => notification))

const icon = computed(() => ({
  name: bonusIcon.value,
  currencyIcon: currencyIcon.value,
}))
</script>

<style scoped>
.text {
  margin: 0;

  b {
    color: var(--text-primary);
  }
}
</style>
